<!-- eslint-disable no-tabs -->
<template>
  <div>
    <div v-if="!isLoading">
      <LoadingProductList></LoadingProductList>
    </div>
    <div v-else>
      <!-- PC -->
      <div class="d-none d-lg-block">
        <div v-for="list in productlist_banner" :key="list">
          <a :href="list.targetUrl">
            <img :src="list.image" alt="全系列商品" class="w-100">
          </a>
        </div>
      </div>
      <!-- 手機 -->
      <div class="d-lg-none">
        <div v-for="list in productlist_m_banner" :key="list">
          <a :href="list.targetUrl">
            <img :src="list.image" alt="全系列商品" class="w-100">
          </a>
        </div>
      </div>
      <!-- 各個產品 -->
      <!-- API寫法 -->
      <!-- 雙11優惠活動 A區 任選1+1 雙倍享受-->
      <h2 class="d-flex justify-content-center mt-lg-6 mt-5 mb-3">任選1+1 雙倍享受</h2>
      <div class="container" style="background-color: #F5F5F6;">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row pb-3 justify-content-center pt-4 pt-md-6">
              <div class="col-md-12 mb-md-3">
                <div class="row gx-4 gx-md-3 gy-5 mb-md-5">
                  <div
                    class="col-md-3 col-6 position-relative"
                    v-for="(item, index) in productlistMain"
                    :key="index"
                  >
                    <div class="card p-0 card-hover position-relative">
                      <router-link
                        :to="`/product/${item.Id}`"
                        class="text-secondary"
                      >
                        <div class="imgContainer ratio ratio-1x1">
                          <img
                            :src="item.Image"
                            class="card-img-top border-0"
                            alt="product image"
                          />
                        </div>
                        <!-- 已售完 -->
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-if="item.RealStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            已售完
                          </div>
                        </div>
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-else-if="item.DisplayStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            售完補貨中
                          </div>
                        </div>
                        <div class="card-body px-3 pb-0">
                          <div class="d-flex">
                            <p class="d-inline-block text-white bg-primary px-2" style="font-size: 14px; text-align: center; width: 117px; overflow: hidden; height: 21px;">{{ item.Slogan }}</p>
                          </div>
                          <p
                            class="card-title card-title-height word-break fs-lg-10 fs-md-7 fs-6 p-1 text-break"
                          >
                            {{ item.Name }}
                          </p>
                          <div
                            style="display: flex; align-items: center; justify-content: start"
                          >
                            <small
                              class="card-text fs-lg-6 fs-md-7 fs-7"
                              style="text-decoration: line-through; width: 3.5rem"
                              >${{ item.MSRP.toLocaleString() }}</small
                            >
                            <p
                              class="text-nowrap text-danger mb-0 fw-bolder fs-lg-5 fs-md-6 fs-6 fs-xl-3"
                            >
                              NT$ {{ item.Price.toLocaleString() }}
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="d-flex justify-content-center mt-lg-6 mt-5 mb-3">優惠加購</h2>
    <!-- 雙11優惠活動 B區($111加購)) -->
      <div class="container" style="background-color: rgb(245, 248, 255);">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row pb-3 justify-content-center pt-4 pt-md-5">
              <div class="col-md-12 mb-md-3">
                <div class="row gx-4 gx-md-3 gy-5 mb-md-5">
                  <h2 class="d-flex justify-content-start fs-10 fs-sm-3 fs-md-2 fs-lg-4">加購價 $111</h2>
                  <div
                    class="col-md-3 col-6 position-relative"
                    v-for="(item, index) in productAddonPlus"
                    :key="index"
                  >
                    <div class="card p-0 card-hover position-relative">
                      <a href="#"
                        @click.prevent="openModal(item.Id)"
                        class="text-secondary"
                      >
                        <div class="imgContainer ratio ratio-1x1">
                          <img
                            :src="item.Image"
                            class="card-img-top border-0"
                            alt="product image"
                          />
                        </div>
                        <!-- 已售完 -->
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-if="item.RealStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            已售完
                          </div>
                        </div>
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-else-if="item.DisplayStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            售完補貨中
                          </div>
                        </div>
                        <div class="card-body px-3 pb-0">
                          <div class="d-flex">
                            <p class="d-inline-block text-white bg-primary px-2" style="font-size: 14px; text-align: center; width: 117px; overflow: hidden; height: 21px;">{{ item.Slogan }}</p>
                          </div>
                          <p
                            class="card-title card-title-height word-break fs-lg-10 fs-md-7 fs-10 p-1 text-break"
                          >
                            {{ item.Name }}
                          </p>
                          <div
                            style="display: flex; align-items: center; justify-content: start"
                          >
                            <small
                              class="card-text fs-lg-6 fs-md-7 fs-7"
                              style="text-decoration: line-through; width: 3.5rem"
                              >${{ item.MSRP.toLocaleString() }}</small
                            >
                            <p
                              class="text-nowrap text-danger mb-0 fw-bolder fs-lg-5 fs-md-6 fs-6 fs-xl-3"
                            >
                              NT$ {{ item.Price.toLocaleString() }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!-- 雙11優惠活動 B區($1111加購)) -->
			<div class="container mb-lg-5 mb-3" style="background-color: #F5F5F6;">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row pb-3 justify-content-center pt-4 pt-md-6">
              <div class="col-md-12 mb-md-3">
                <div class="row gx-4 gx-md-3 gy-5 mb-md-5">
                  <h2 class="d-flex justify-content-start fs-10 fs-sm-3 fs-md-2 fs-lg-4">加購價 $1111</h2>
                  <div
                    class="col-md-3 col-6 position-relative"
                    v-for="(item, index) in productAddonTwo"
                    :key="index"
                  >
                    <div class="card p-0 card-hover position-relative">
                      <a href="#"
                        @click.prevent="openModal(item.Id)"
                        class="text-secondary"
                      >
                        <div class="imgContainer ratio ratio-1x1">
                          <img
                            :src="item.Image"
                            class="card-img-top border-0"
                            alt="product image"
                          />
                        </div>
                        <!-- 已售完 -->
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-if="item.RealStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            已售完
                          </div>
                        </div>
                        <div
                          class="position-absolute start-0 top-0 opacity-75 sold_out"
                          v-else-if="item.DisplayStock === 0"
                        >
                          <div
                            class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                          >
                            售完補貨中
                          </div>
                        </div>
                        <div class="card-body px-3 pb-0">
                          <div class="d-flex">
                            <p class="d-inline-block text-white bg-primary px-2" style="font-size: 14px; text-align: center; width: 117px; overflow: hidden; height: 21px;">{{ item.Slogan }}</p>
                          </div>
                          <p
                            class="card-title card-title-height word-break fs-lg-10 fs-md-7 fs-10 p-1 text-break"
                          >
                            {{ item.Name }}
                          </p>
                          <div
                            style="display: flex; align-items: center; justify-content: start"
                          >
                            <small
                              class="card-text fs-lg-6 fs-md-7 fs-7"
                              style="text-decoration: line-through; width: 3.5rem"
                              >${{ item.MSRP.toLocaleString() }}</small
                            >
                            <p
                              class="text-nowrap text-danger mb-0 fw-bolder fs-lg-5 fs-md-6 fs-6 fs-xl-3"
                            >
                              NT$ {{ item.Price.toLocaleString() }}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <AddonProduct ref="addonModal" :product-id-double="productIdDouble"></AddonProduct><!-- 無法用駝峰寫法，所以用 product-id-double-->
    </div>
  </div>
</template>
<script>
import LoadingProductList from '@/views/Loading/LoadingProductList.vue'
import ProductListSvc from '@/service/productlist-service.js'
import BannerSvc from '@/service/banner-service.js'
import AddonProduct from '@/components/AddonProduct.vue'

export default {
  data () {
    return {
      productlistMain: [], //* 雙11主商品(A區)
      productAddon: [], //* 雙11加購商品(B區)
      productAddonPlus: [], //* 雙11 $111區
      productAddonTwo: [], //* 雙11 $1111區
      isLoading: false,
      productlist_banner: [], //* Banner(桌機)
      productlist_m_banner: [], //* Banner(手機)
      productIdDouble: '' //* modal元件
    }
  },
  components: {
    LoadingProductList,
    AddonProduct
  },
  methods: {
    getDoubleEvent () {
      ProductListSvc.GetAddonProductList('01').then(list => {
        this.isLoading = true
        this.productItem = list
        this.GA_view_item_list()
        if (list) {
          this.productlistMain = list
        }
      })
      ProductListSvc.GetAddonProductList('02').then(list => {
        this.isLoading = true
        this.productAddon = list //* 加購區的全部商品
        if (list) {
          this.productAddon = list
        }
        //* 加購區$111專區
        this.productAddonPlus = this.productAddon.filter(item => {
          return item.SubCatagory === 'S07030201'
        })
        //* 加購區$1111專區
        this.productAddonTwo = this.productAddon.filter(item => {
          return item.SubCatagory === 'S07030202'
        })
      })
    },
    getProductImage () {
      BannerSvc.GetEventBanner('Double_Banner').then((data) => {
        if (data) {
          this.productlist_banner = data
        }
      })
      BannerSvc.GetEventBanner('Double_m_Banner').then((data) => {
        if (data) {
          this.productlist_m_banner = data
        }
      })
    },
    openModal (id) {
      this.productIdDouble = id
      this.$refs.addonModal.openModal()
    },
    GA_view_item_list () {
      const dataForGA = {
        items: []
      }
      dataForGA.items = JSON.parse(JSON.stringify(this.productItem))
      dataForGA.items.forEach(item => {
        item.item_id = item.Id // ?產品代碼
        item.item_name = item.Name // ?產品名稱
        item.price = item.Price // ?末端售價
        item.discount = (item.MSRP - item.Price) / item.MSRP // ?折扣率
        delete item.Date
        delete item.Image
        delete item.Slogan
        delete item.Id
        delete item.Name
        delete item.Stock
        delete item.MSRP
        delete item.Price
      })
      this.$gtag.event('view_item_list', dataForGA)
    }
  },
  mounted () {
    this.getProductImage()
    this.getDoubleEvent()
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-color: #c8c9cc;
}

.contain {
  width: 130px;
  height: 35px;
  color: #fff;
  line-height: 1.5;
  background: gray;
  border-radius: 30px;
  margin-top: -15%;
}

.sold_out {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border: #fff;
  background: #fff;
}

</style>
